'use client';

import { node, number } from 'prop-types';
import { useEffect, useState } from 'react';

const Delay = ({ children, delay, fallback }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => setVisible(true), delay);
  }, [delay]);

  return visible ? children : fallback;
};

Delay.propTypes = {
  children: node.isRequired,
  delay: number.isRequired,
  fallback: node.isRequired,
};

export default Delay;
