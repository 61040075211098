'use client';

import { array, node, object } from 'prop-types';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { usePathname } from 'next/navigation';

import { toPathname } from '@personly/libs-pathnames';
import { useLocalizedPathname } from '@personly/libs-hooks';

const sort = (a, b) => {
  if (a.title > b.title) return 1;
  if (a.title < b.title) return -1;
  return 0;
};

const MenusContext = createContext();

const MenusProvider = ({ children, languages, menu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const pathname = usePathname();
  const localizedPathname = useLocalizedPathname(languages);

  const matchedEntry = useMemo(
    () =>
      menu.find((e) => {
        const currentPathname = localizedPathname(toPathname(e.slug));
        const regexPathname = currentPathname.replaceAll('/', '\\/');
        const regex = new RegExp(`^${regexPathname}(\\/.*)?$`);
        return regex.test(pathname);
      }),
    [localizedPathname, pathname, menu]
  );

  const makeRoute = useCallback(
    (slug, group, title) => {
      const href = localizedPathname(toPathname(slug));
      const active = matchedEntry ? matchedEntry.slug === slug : false;
      return { active, group, href, title };
    },
    [localizedPathname, matchedEntry]
  );

  const routes = useMemo(
    () =>
      menu
        .filter((e) => (matchedEntry ? e.group === matchedEntry.group : true))
        .reduce((acc, e) => {
          if (!matchedEntry) {
            const i = acc.findIndex((a) => a.group === e.group);
            if (i === -1) acc.push(makeRoute(e.slug, e.group, e.group));
            return acc;
          }

          const route = makeRoute(e.slug, e.group, e.title);
          return [...acc, route];
        }, [])
        .sort(sort),
    [matchedEntry, makeRoute, menu]
  );

  const title = useMemo(
    () => (matchedEntry ? matchedEntry.title : 'Home'),
    [matchedEntry]
  );

  const toggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const breadcrumbs = useMemo(() => {
    const result = [{ slug: '/', id: 'HOME' }];
    if (matchedEntry)
      result.push({ slug: matchedEntry.slug, id: matchedEntry.title });
    return result;
  }, [matchedEntry]);

  const value = { toggle, isOpen, routes, title, breadcrumbs };

  return (
    <MenusContext.Provider value={value}>{children}</MenusContext.Provider>
  );
};

MenusProvider.propTypes = {
  children: node.isRequired,
  languages: object.isRequired,
  menu: array.isRequired,
};

function useMenus() {
  const context = useContext(MenusContext);
  if (context === undefined) throw new Error('useMenus requires a context!');

  return context;
}

export { MenusProvider, useMenus };
