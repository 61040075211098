'use client';

import { number, string } from 'prop-types';
import Image from 'next/image';

import { useSchemes } from '@personly/libs-providers';

const BrandScheme = ({ alt, height = 26 }) => {
  const { schemeHandle } = useSchemes();

  const src = `/images/brands/personly-heading-${schemeHandle.toLowerCase()}.svg`;
  const width = Math.floor(height * 4);

  return <Image src={src} width={width} height={height} alt={alt} />;
};

BrandScheme.propTypes = { alt: string.isRequired, height: number };

export default BrandScheme;
