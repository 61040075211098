'use client';

import classnames from 'classnames';
import { node } from 'prop-types';

import { useMenus } from '@personly/libs-providers';

const className = classnames(
  'border-0',
  'inline-block',
  'align-middle',
  'text-menu-link-color',
  'bg-menu-background-color'
);

const MenuTogglerButton = ({ children }) => {
  const { toggle } = useMenus();

  return (
    <button
      aria-label="Toggle Nav"
      onClick={toggle}
      type="button"
      className={className}
    >
      {children}
    </button>
  );
};

MenuTogglerButton.propTypes = { children: node.isRequired };

export default MenuTogglerButton;
