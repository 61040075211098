'use client';

import { node } from 'prop-types';

import { useMenus } from '@personly/libs-providers';

const MenuLinks = ({ children }) => {
  const { isOpen } = useMenus();

  const classNames = ['hidden', 'flex-col', 'lg:flex'];
  if (isOpen) classNames.push('!flex');

  return <div className={classNames.join(' ')}>{children}</div>;
};

MenuLinks.propTypes = { children: node.isRequired };

export default MenuLinks;
